<template>
  <CCard>
    <CCardHeader>
      <router-link :to="'/calendar/add-event/' + this.calendar.id + '/0'" class="calendar_button"><CButton color="success" block>{{$t('Добавить событие')}}</CButton></router-link>
      <router-link :to="'/calendar/add-page/' + this.calendar.id" class="calendar_button"><CButton color="success" block>{{$t('Добавить подписную страницу')}}</CButton></router-link>
      <router-link v-if="this.calendar.categories_enabled" :to="'/calendar/categories/' + this.calendar.id" class="calendar_button"><CButton color="success" block>{{$t('Категории')}}</CButton></router-link>
      <br>
      <br>
      <p>{{$t('Название')}}: {{ this.calendar.name }}</p>
      <p>{{$t('Описание')}}: {{ this.calendar.desc }}</p>
      <p>
        {{$t('Скрипт для вставки на ваш сайт')}}:
        <span class="script" @click="copyTextToClipboard('script')" :title="this.$t('Скопировать')">{{this.script}}">&lt;/script></span>
        <span class="copyTextToClipboard" @click="copyTextToClipboard('script')" :title="this.$t('Скопировать')"><CIcon name="cil-copy"/></span>
      </p>
      <p class="script_desc">
        {{$t('Скрипт нужно вставить на Ваш сайт, если Вы хотите, чтобы подписки шли с него.')}}
        <br>
        {{$t('При этом у элемента...')}}
      </p>
      <p>
        {{$t('Ссылка на общий ics-файл')}}:
        <span class="icsLink" @click="copyTextToClipboard('icsLink')" :title="this.$t('Скопировать')">{{this.calendar.ics}}</span>
        <span class="copyTextToClipboard" @click="copyTextToClipboard('icsLink')" :title="this.$t('Скопировать')"><CIcon name="cil-copy"/></span>
      </p>
      <p>{{$t('Подписные страницы')}}:</p>
      <table class="pages_table">
        <tr v-for="(page, index) in this.pages" :key="index">
          <td>
            <a target="_blank" :href="link(page.code)" class="calendar_sign_link">{{ link(page.code) }}</a>
          </td>
          <td>
            <router-link :to="editPageLink(page.code)" class="calendar_sign_link_action"><CIcon name="cil-pencil"/></router-link>
          </td>
          <td>
            {{ $t('конверсия') + ' ' + page.conversion}} %
          </td>
          <td>
            {{ $t('посетителей') + ' ' + page.visitors}} ( apple {{page.visitors_apple}}, google {{page.visitors_google}} )
          </td>
          <td>
            {{ $t('подписчиков') + ' ' + page.subscribed}} ( apple {{page.subscribed_apple}}, google {{page.subscribed_google}} )
          </td>
          <td>
            {{ $t('отписавшихся') + ' ' + page.unsubscribed}}
          </td>
          <td>
            {{ $t('Страница с автоподпиской') + ' ' + link(page.code) + '?r=t'}}
          </td>
        </tr>
      </table>
    </CCardHeader>
    <CCardBody>
      <full-calendar
          :events="events"
          :config="config"
      ></full-calendar>
    </CCardBody>
  </CCard>
</template>

<script>
import 'fullcalendar/dist/fullcalendar.css'
import axios from '@/modules/axios'
import globalConfig from "../../config";

export default {
  name: 'Calendar',
  components: {
  },
  data () {
    return {
      globalConfig: globalConfig,
      config: {
        locale: this.$i18n.locale,
        defaultView: 'month',
        eventClick: (event) => {
          this.$router.push('/calendar/edit-event/' + event.calendar_id + '/' + event.id)
        }
        // drop(...args) {
        //   //handle drop logic in parent
        // },
      },
      calendar: {
        name: '',
        desc: '',
        categories_enabled: false,
        categories_show: false,
        ics: ''
      },
      timeZone: 'local',
      pages: [],
      events: [
        {
          title  : 'event1',
          start  : '2021-04-01',
        },
        {
          title  : 'event2',
          start  : '2021-04-05',
          end    : '2021-04-07',
        },
        {
          title  : 'event3',
          start  : '2021-04-09T12:30:00',
          allDay : false,
        },
      ],
    }
  },
  computed: {
    script () {
      return '<script type="text/javascript" src="' + globalConfig.api_server + 'magic.js?c='
          + this.calendar.id
    }
  },
  created() {
    this.getCalendar()
    this.getEvents()
    this.getPages()
  },
  methods: {
    getCalendar () {
      axios.get('/calendar/info', {
        params: {id: this.$route.params.id}
      }).then((res) => {
        this.calendar = res.data.data;
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    getEvents () {
      axios.get('/event/list', {
        params: {calendar_id: this.$route.params.id}
      }).then((res) => {
        this.events = res.data.data;
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    getPages () {
      axios.get('/page/list', {
        params: {calendar_id: this.$route.params.id, withStat: true}
      }).then((res) => {
        this.pages = res.data.data;
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    link (code) {
      const protocol = location.host.includes('localhost') ? 'http' : 'https'
      return (protocol + '://' + location.host + '/' + code)
    },
    editPageLink(code) {
      return '/calendar/edit-page/' + this.calendar.id + '/' + code
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement("textarea")
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    copyTextToClipboard(elClass) {
      const text = document.getElementsByClassName(elClass)[0].innerText
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(() => {
        alert(this.$t('Скопировано'));
      }, function(err) {
        console.log('Async: Could not copy text: ', err);
      });
    }
  }
}
</script>
<style lang="scss">
.calendar_sign_link {
  margin-right: 20px;
  cursor: pointer;
  width: unset;
}
.calendar_sign_link_action {
  color: black;
  width: unset;
}
.calendar_button {
  width: 25%;
  display: inline-block;
  margin-right: 5%;
  text-decoration: none !important;
}
.pages_table td {
  padding: 0 20px;
}
.script, .icsLink {
  display: inline-block;
  padding: 0.2em 0.5em;
  border: 1px solid gray;
  background: #ebedef;
}
.copyTextToClipboard {
  margin-left: 1em;
  cursor: pointer;
  &:hover {
    color: black;
  }
}
.script_desc {
  font-size: 0.9em;
  color: grey;
}
</style>
